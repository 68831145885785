.app-container {
  display: flex;
  flex-direction: column;
  background-color: black;
}

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: black;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100vw;
  max-height: 100vh;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 10px auto;
  margin-top: 10px;
}

.logo-container {
  display: flex;
  gap: 10px;
  /* width: 10vw; */
}
.working {
  font-size: 30px;
}
@font-face {
  font-family: "Hello mixed";
  src: url("./font//Hello-Mixed.otf");
}

@media screen and (max-width: 900px) {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 10px auto;
    margin-top: 30px;
  }

  .coming-soon {
    margin-top: 140px;
  }

  .meal {
    margin-top: 60px;
  }

  .working {
    margin-top: 30px;
    font-size: 15px;
    margin-bottom: 30px;
  }

  .crave-image {
    position: absolute;
    top: 22%;
    left: 32%;
    /* transform: translate(-50%, -50%); */
    width: 100%;
  }

}
